.containerform {
  width: 100%;
  max-width: 600px;
}
.button {
  width: 100%;
}
.logoform {
  margin-bottom: 24px;
  width: 100%;
  padding: 24px;
}
.input_place {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
}
.labelinput {
  font-size: 1.5rem;
}

.button {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
}
.button:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
.error {
  background-color: #bd4242;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 24px;
}

.inputform {
  padding: 12px 6px 6px 6px;
  font-size: 1.2rem;
  background-color: var(--transparent);
  border: 0px;
  border-bottom: 2px solid var(--color_second);
  transition: all 0.5s;
  color: var(--color_third);
  border-radius: 10px 10px 0px 0px;
}

.inputform:hover,
.inputform:focus {
  border-bottom: 2px solid var(--color_fourth);
  color: var(--color_fourth);
}
