.body_landing {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container_image {
  width: 100%;
  background-image: url(https://images.pexels.com/photos/5922447/pexels-photo-5922447.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.container_second_image {
  width: 100%;
  background-image: url(https://images.pexels.com/photos/8249857/pexels-photo-8249857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.visibility_second {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  padding: 12rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: all 0.5s;
  text-align: right !important;
  color: var(--color_first);
  background: linear-gradient(
    90deg,
    var(--transparent) 31%,
    var(--color_second) 64%,
    var(--color_second) 98%,
    var(--transparent) 100%
  );
}

.visibility {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  padding: 12rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.5s;

  background: linear-gradient(
    270deg,
    var(--transparent) 31%,
    var(--color_first) 64%,
    var(--color_first) 98%,
    var(--transparent) 100%
  );
}
.titleimage {
  font-size: 3rem;
  font-family: var(--font_second);
  margin-bottom: 12px;
  color: var(--color_second);
}

.second_titleimage {
  font-size: 3rem;
  font-family: var(--font_second);
  margin-bottom: 12px;
  color: var(--color_first);
}

.subtitleimage {
  font-size: 1.1rem;
  font-family: var(--font_first);
  margin-bottom: 12px;
}

.button {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
}
.button:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
.general_title {
  width: 100%;
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}
.container_icons {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px;
}
.circle_icon {
  align-items: center;
  width: 20%;
  border-radius: 300px;
  display: flex;
  flex-direction: column;
  padding: 64px 12px;
  background-color: var(--color_second);
  color: var(--color_first);
  text-align: center;
}
.icon {
  font-size: 4rem;
  margin-bottom: 24px;
}

@media screen and (max-width: 850px) {
  .circle_icon {
    width: 40%;
    margin-bottom: 24px;
  }
  .visibility {
    padding: 6rem 2rem 3rem 2rem;
    justify-content: flex-end;
    background: linear-gradient(
      360deg,
      var(--transparent) 0%,
      var(--color_first) 30%,
      var(--color_first) 69%,
      var(--transparent) 100%
    );
  }
}
