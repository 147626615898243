.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.filter {
  padding: 32px;
  display: flex;
  justify-content: space-around;
}
.uniquefilter {
  width: 40%;
}

.inputform {
  padding: 12px 6px 6px 6px;
  font-size: 1.2rem;
  background-color: var(--transparent);
  border: 0px;
  border-bottom: 2px solid var(--color_second);
  transition: all 0.5s;
  color: var(--color_third);
  border-radius: 10px 10px 0px 0px;
}

.inputform:hover,
.inputform:focus {
  border-bottom: 2px solid var(--color_fourth);
  color: var(--color_fourth);
}
.button {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
  margin-bottom: 24px;
}
.button:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
.containerlist {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1400px;
}
.unicolist {
  width: 90%;
  padding: 12px 24px;

  border-radius: 10px;
  text-align: center;
  border: 1px dashed var(--color_fourth);
  margin: auto;
  margin-bottom: 12px;
}
