@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Rubik&display=swap);
.header_header__19eW9 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  max-width: 1400px;
}

.header_logo_image__2gORv {
  width: 13rem;
}
.header_back_header__29YyY {
  width: 100%;
  padding: 6px 24px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  background-color: var(--color_first);
  z-index: 1;
}

.header_container_options__19abd {
  width: -webkit-fit-content;
  width: fit-content;
  max-width: 100%;
  display: flex;
}

.header_text_head__1m_xr {
  font-size: 1.5rem;
  color: var(--color_third);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
}
.header_text_head__1m_xr:hover {
  color: var(--color_second);
}

.header_text_head__1m_xr:hover .header_icon_head__3-sXm {
  color: var(--color_second);
}

.header_icon_head__3-sXm {
  color: var(--color_fourth);
  transition: all 0.7s;
  margin-right: 6px;
}
@media screen and (max-width: 650px) {
  .header_header__19eW9 {
    flex-direction: column;
    align-items: flex-start;
  }
  .header_text_head__1m_xr {
    margin-bottom: 12px;
  }
}

.login_containerform__2pCn7 {
  width: 100%;
  max-width: 600px;
}
.login_button__2lE6N {
  width: 100%;
}
.login_logoform__2SGPc {
  margin-bottom: 24px;
  width: 100%;
  padding: 24px;
}
.login_input_place__-Y65j {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
}
.login_labelinput__3p60A {
  font-size: 1.5rem;
}

.login_button__2lE6N {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
}
.login_button__2lE6N:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
.login_error__3ZW3k {
  background-color: #bd4242;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 24px;
}

.login_inputform__5Nd8W {
  padding: 12px 6px 6px 6px;
  font-size: 1.2rem;
  background-color: var(--transparent);
  border: 0px;
  border-bottom: 2px solid var(--color_second);
  transition: all 0.5s;
  color: var(--color_third);
  border-radius: 10px 10px 0px 0px;
}

.login_inputform__5Nd8W:hover,
.login_inputform__5Nd8W:focus {
  border-bottom: 2px solid var(--color_fourth);
  color: var(--color_fourth);
}

.solicite_container__3J6xb {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.solicite_title__2aXWC {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 24px;
}
.solicite_timer__3ctjk {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  padding: 32px;
  color: var(--color_fourth);
}
.solicite_mapcontainer__3q-wI {
  width: 70vh;
  max-width: 100%;
  position: relative;
  height: 70vh;
  background-image: url(/static/media/map_line.5d198e0c.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border: 2px dotted var(--color_second);
}
.solicite_iconposition__31SzM {
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 3rem;
  color: var(--color_second);
  -webkit-transform: translate(-1.5rem, 1.5rem);
          transform: translate(-1.5rem, 1.5rem);
}
.solicite_iconcar__2haOq {
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 1.5rem;
  -webkit-transform: translate(-1rem, 1rem);
          transform: translate(-1rem, 1rem);
}
.solicite_icongaso__2mB8H {
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 3rem;
  color: var(--color_fourth);
  -webkit-transform: translate(-1.5rem, 1.5rem);
          transform: translate(-1.5rem, 1.5rem);
}
.solicite_inputcant__2t4WI {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding: 24px;
}
.solicite_textinputcars__ZCmHH {
  font-size: 1.3rem;
  padding-right: 24px;
}
.solicite_rangue_cant__1WsFU {
  width: 70%;
}
.solicite_inputran__2kwLq {
  width: 100%;
}
.solicite_modify_position__1s5cw {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.solicite_inputposition__2cNiy {
  width: 40%;
}
.solicite_breath__1PSKB {
  margin-bottom: 120px;
}
.solicite_inputform__2Gks8 {
  padding: 12px 6px 6px 6px;
  font-size: 1.2rem;
  background-color: var(--transparent);
  border: 0px;
  border-bottom: 2px solid var(--color_second);
  transition: all 0.5s;
  color: var(--color_third);
  border-radius: 10px 10px 0px 0px;
}

.solicite_inputform__2Gks8:hover,
.solicite_inputform__2Gks8:focus {
  border-bottom: 2px solid var(--color_fourth);
  color: var(--color_fourth);
}

.solicite_button__1ElPf {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
}
.solicite_button__1ElPf:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}

.listservice_container__2hBgt {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.listservice_filter__1lwQO {
  padding: 32px;
  display: flex;
  justify-content: space-around;
}
.listservice_uniquefilter__1Rleh {
  width: 40%;
}

.listservice_inputform__2Ry8u {
  padding: 12px 6px 6px 6px;
  font-size: 1.2rem;
  background-color: var(--transparent);
  border: 0px;
  border-bottom: 2px solid var(--color_second);
  transition: all 0.5s;
  color: var(--color_third);
  border-radius: 10px 10px 0px 0px;
}

.listservice_inputform__2Ry8u:hover,
.listservice_inputform__2Ry8u:focus {
  border-bottom: 2px solid var(--color_fourth);
  color: var(--color_fourth);
}
.listservice_button__2n8v_ {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
  margin-bottom: 24px;
}
.listservice_button__2n8v_:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
.listservice_containerlist__2TfKF {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1400px;
}
.listservice_unicolist__3qr4Z {
  width: 90%;
  padding: 12px 24px;

  border-radius: 10px;
  text-align: center;
  border: 1px dashed var(--color_fourth);
  margin: auto;
  margin-bottom: 12px;
}

.landing_body_landing__jFXpq {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.landing_container_image__3y4Tr {
  width: 100%;
  background-image: url(https://images.pexels.com/photos/5922447/pexels-photo-5922447.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.landing_container_second_image__3BOsr {
  width: 100%;
  background-image: url(https://images.pexels.com/photos/8249857/pexels-photo-8249857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.landing_visibility_second__10lAa {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  padding: 12rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: all 0.5s;
  text-align: right !important;
  color: var(--color_first);
  background: linear-gradient(
    90deg,
    var(--transparent) 31%,
    var(--color_second) 64%,
    var(--color_second) 98%,
    var(--transparent) 100%
  );
}

.landing_visibility__2driW {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  padding: 12rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.5s;

  background: linear-gradient(
    270deg,
    var(--transparent) 31%,
    var(--color_first) 64%,
    var(--color_first) 98%,
    var(--transparent) 100%
  );
}
.landing_titleimage__29flm {
  font-size: 3rem;
  font-family: var(--font_second);
  margin-bottom: 12px;
  color: var(--color_second);
}

.landing_second_titleimage__2b9CY {
  font-size: 3rem;
  font-family: var(--font_second);
  margin-bottom: 12px;
  color: var(--color_first);
}

.landing_subtitleimage__2qCkZ {
  font-size: 1.1rem;
  font-family: var(--font_first);
  margin-bottom: 12px;
}

.landing_button__DxbMI {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
}
.landing_button__DxbMI:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
.landing_general_title__2Weam {
  width: 100%;
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}
.landing_container_icons__2s8qq {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px;
}
.landing_circle_icon__J5ulC {
  align-items: center;
  width: 20%;
  border-radius: 300px;
  display: flex;
  flex-direction: column;
  padding: 64px 12px;
  background-color: var(--color_second);
  color: var(--color_first);
  text-align: center;
}
.landing_icon__2WuRA {
  font-size: 4rem;
  margin-bottom: 24px;
}

@media screen and (max-width: 850px) {
  .landing_circle_icon__J5ulC {
    width: 40%;
    margin-bottom: 24px;
  }
  .landing_visibility__2driW {
    padding: 6rem 2rem 3rem 2rem;
    justify-content: flex-end;
    background: linear-gradient(
      360deg,
      var(--transparent) 0%,
      var(--color_first) 30%,
      var(--color_first) 69%,
      var(--transparent) 100%
    );
  }
}

/* global variables */

:root {
  --color_first: #191e38;
  --color_second: #ddfb2f;
  --color_third: #f1f2ff;
  --color_fourth: #0897f2;
  --transparent: #00000000;
  --font_first: 'Rubik', sans-serif;
  --font_second: 'Nunito', sans-serif;
}

svg path {
  stroke: inherit;
}

/* document root */

* {
  box-sizing: border-box;
  touch-action: manipulation;
  text-decoration: none;
}

body {
  margin: 0px;
  font-family: 'Rubik', sans-serif;
  font-family: var(--font_first);
  background-color: #191e38;
  background-color: var(--color_first);
  color: #f1f2ff;
  color: var(--color_third);
}

*::-webkit-scrollbar {
  background: #ddfb2f;
  background: var(--color_second);
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  background: 'Rubik', sans-serif;
  background: var(--font_first);
  border-radius: 100px;
}

textarea:focus,
input:focus {
  outline: none;
}

input:-internal-autofill-selected {
  background-color: #00000000 !important;
  background-color: var(--transparent) !important;
  color: #ddfb2f !important;
  color: var(--color_second) !important;
}
::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

