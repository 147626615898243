.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 24px;
}
.timer {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  padding: 32px;
  color: var(--color_fourth);
}
.mapcontainer {
  width: 70vh;
  max-width: 100%;
  position: relative;
  height: 70vh;
  background-image: url(./../../image/map_line.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border: 2px dotted var(--color_second);
}
.iconposition {
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 3rem;
  color: var(--color_second);
  transform: translate(-1.5rem, 1.5rem);
}
.iconcar {
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 1.5rem;
  transform: translate(-1rem, 1rem);
}
.icongaso {
  position: absolute;
  bottom: 0%;
  left: 0%;
  font-size: 3rem;
  color: var(--color_fourth);
  transform: translate(-1.5rem, 1.5rem);
}
.inputcant {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding: 24px;
}
.textinputcars {
  font-size: 1.3rem;
  padding-right: 24px;
}
.rangue_cant {
  width: 70%;
}
.inputran {
  width: 100%;
}
.modify_position {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.inputposition {
  width: 40%;
}
.breath {
  margin-bottom: 120px;
}
.inputform {
  padding: 12px 6px 6px 6px;
  font-size: 1.2rem;
  background-color: var(--transparent);
  border: 0px;
  border-bottom: 2px solid var(--color_second);
  transition: all 0.5s;
  color: var(--color_third);
  border-radius: 10px 10px 0px 0px;
}

.inputform:hover,
.inputform:focus {
  border-bottom: 2px solid var(--color_fourth);
  color: var(--color_fourth);
}

.button {
  margin-top: 24px;
  padding: 12px 24px;
  text-align: center;
  background-color: var(--color_fourth);
  border: 0px;
  border-radius: 5px;
  font-size: 1.3rem;
  font-family: var(--font_second);
  color: var(--color_third);
  transition: all 0.5s;
  cursor: pointer;
}
.button:hover {
  background-color: var(--color_second);
  color: var(--color_first);
}
