@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Rubik&display=swap');

/* global variables */

:root {
  --color_first: #191e38;
  --color_second: #ddfb2f;
  --color_third: #f1f2ff;
  --color_fourth: #0897f2;
  --transparent: #00000000;
  --font_first: 'Rubik', sans-serif;
  --font_second: 'Nunito', sans-serif;
}

svg path {
  stroke: inherit;
}

/* document root */

* {
  box-sizing: border-box;
  touch-action: manipulation;
  text-decoration: none;
}

body {
  margin: 0px;
  font-family: var(--font_first);
  background-color: var(--color_first);
  color: var(--color_third);
}

*::-webkit-scrollbar {
  background: var(--color_second);
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  background: var(--font_first);
  border-radius: 100px;
}

textarea:focus,
input:focus {
  outline: none;
}

input:-internal-autofill-selected {
  background-color: var(--transparent) !important;
  color: var(--color_second) !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
