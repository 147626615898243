.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  max-width: 1400px;
}

.logo_image {
  width: 13rem;
}
.back_header {
  width: 100%;
  padding: 6px 24px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  background-color: var(--color_first);
  z-index: 1;
}

.container_options {
  width: fit-content;
  max-width: 100%;
  display: flex;
}

.text_head {
  font-size: 1.5rem;
  color: var(--color_third);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
}
.text_head:hover {
  color: var(--color_second);
}

.text_head:hover .icon_head {
  color: var(--color_second);
}

.icon_head {
  color: var(--color_fourth);
  transition: all 0.7s;
  margin-right: 6px;
}
@media screen and (max-width: 650px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .text_head {
    margin-bottom: 12px;
  }
}
